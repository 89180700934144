import { createComputed, Show } from "solid-js";
import { RouteSectionProps } from "@solidjs/router";

import Spinner from "@repo/ui/loaders/Spinner";
import UString from "@repo/utils/UString";
import AppRouter from "@repo/utils-solid/AppRouter";

import { useAdmin } from "~/state/features/admin";

export default function Authenticated({ children }: D<RouteSectionProps>) {
  const [admin] = useAdmin();

  createComputed(() => {
    if (admin.authenticated() === false)
      AppRouter.replace(
        `/login?redirect=${encodeURIComponent(UString.afterPrefix(window.location.href, window.location.origin))}`
      );
  });

  return (
    <Show when={admin.authenticated()} fallback={<Spinner.Common />}>
      {children}
    </Show>
  );
}
